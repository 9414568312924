.ferozibg {
  background: #e5f8ff !important;
}

.board {
  width: 100%;
}

.game {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  // padding-top: 50px;
  flex-direction: column;
}

.letter {
  font-size: 20px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  // flex: 10%;
  // width: 80px;
  height: 50px;
  background-color: white;
  border-radius: 12px;
  // margin: 5px 8px;
  // display: grid;
  place-items: center;
  font-family: "Inter", sans-serif !important;
}

#correct {
  background-color: #43ff35;
}

#partiallyCorrect {
  background-color: yellow;
}

#error {
  background-color: #5c5b5d;
  color: white;
}

.keyboard {
  width: 100%;
  // height: 300px;
}

.key {
  font-family: "Inter", sans-serif !important;
  font-size: 15.39px;
  background-color: white;
  font-weight: 500;
  color: rgb(0, 0, 0);
  width: 45px;
  height: 50px;
  margin: 3px;
  border-radius: 4px;
  display: grid;
  padding-inline: 3px !important;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #1782ff;
    color: white !important;
  }
}

.line3Keys {
  #large {
    &:last-child {
      border: 1px solid #1782ff !important;
      color: #1782ff !important;
      &:hover {
        border: 1px solid white !important;
        color: white !important;
      }
    }
  }
}

#large {
  width: 100px;
}

#disabled {
  background-color: #1782ff;
  color: white;
}

.line1Keys {
  flex: 30%;
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin: 5px;
}

.line2Keys {
  flex: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}

.line3Keys {
  flex: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}
