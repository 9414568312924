@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

:root {
    --bs-blue: #3461FD;
}

.bg_blue {
    background-color: var(--bs-blue) !important;
}

.manrope {
    font-family: "Manrope", sans-serif !important;
    font-style: normal;
}

.new-phone-number-field {
    .PhoneInputCountryIcon--border {
        box-shadow: none !important;
        background: transparent !important;
    }

    input {
        &:focus-visible {
            outline: none;
        }

        font-family: "Manrope",
        sans-serif !important;
        padding-left: 8px;
        padding-top: 13px;
        padding-bottom: 13px;
        width: 100% !important;
        border-radius: 8px;
        border: 1.5px solid rgba(204, 204, 204, 1) !important;
        height: auto !important;
        font-weight: 500;
    }

    background: transparent;

    .PhoneInputCountry {
        padding: 8px;
        border: 1.5px solid rgba(204, 204, 204, 1);
        border-radius: 8px;

        .PhoneInputCountryIcon {
            background-color: transparent !important;

            img {

                object-fit: cover;
                object-position: center;
                width: 20px !important;
                height: 20px !important;
                clip-path: circle(50%);
            }


            padding: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}

.offerRewards {
    .offer-search {
        input {
            color: #989DA3 !important;

            &::placeholder {
                color: #989DA3 !important;
            }
        }
    }

    .nav-pills {
        .nav-link {
            color: #475467 !important;
            border-bottom: 2px solid #E1E1E1 !important;
        }

        .nav-link.active {
            color: var(--bs-blue) !important;
            border-bottom: 2px solid var(--bs-blue) !important;

        }
    }
}

.nearby-map {
    iframe {
        height: 100% !important;
    }
}

.heart-container {
    --heart-color: rgb(255, 91, 137);
    position: relative;
    width: 20px;
    height: 20px;
    transition: .3s;
}

.heart-container .checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 20;
    cursor: pointer;
}

.heart-container .svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heart-container .svg-outline {
    fill: #9A9BA2;
    position: absolute;
}

.heart-container .svg-filled {
    fill: var(--heart-color);
    position: absolute;
}

.heart-container .svg-filled {
    animation: keyframes-svg-filled 1s;
    display: none;
}

.heart-container .svg-celebrate {
    position: absolute;
    animation: keyframes-svg-celebrate .5s;
    animation-fill-mode: forwards;
    display: none;
    stroke: var(--heart-color);
    fill: var(--heart-color);
    stroke-width: 2px;
}

.heart-container .checkbox:checked~.svg-container .svg-filled {
    display: block
}

.heart-container .checkbox:checked~.svg-container .svg-celebrate {
    display: block
}

@keyframes keyframes-svg-filled {
    0% {
        transform: scale(0);
    }

    25% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1);
        filter: brightness(1.5);
    }
}

@keyframes keyframes-svg-celebrate {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
        filter: brightness(1.5);
    }

    100% {
        transform: scale(1.4);
        opacity: 0;
        display: none;
    }
}

.manage-detail-main {
    .animated-field {
        border: 1.5px solid rgb(204, 204, 204);

        >div {
            div {
                &::after {
                    display: none !important;
                }

                &::before {
                    display: none !important;
                }
            }
        }

        .MuiFilledInput-underline {
            background-color: white !important;
        }

        label {
            color: #4D4D4D !important;
            font-size: 15px !important;
            font-family: "Manrope", sans-serif !important;

        }

        label.Mui-focused {
            font-size: 18px !important;

        }

        .css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
            color: #4D4D4D !important;
            font-size: 18px !important;
        }

        .MuiFormLabel-filled {
            font-size: 18px !important;
        }

        input {
            // padding: 0px !important;
            font-family: "Manrope", sans-serif !important;
            font-weight: 500 !important;
            font-size: 15px !important;
            border-radius: 0.5rem !important;

            color: #1A1A1A !important;

        }

        // .css-1ff8729-MuiInputBase-root-MuiFilledInput-root:after {

        //         display: none !important;
        // }

        // &::before {

        //     display: none !important;
        // }
        // }
        // .css-1ff8729-MuiInputBase-root-MuiFilledInput-root:before{
        //     display: none !important;
        // }
        .css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
            font-size: 18px !important;
        }

        .css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
            font-size: 18px !important;
        }

    }
}

.manag-detail {
    // .manag-detail-field {
    //     border: 1.5px solid rgba(204, 204, 204, 1);

    //     label {
    //         color: rgba(77, 77, 77, 1);
    //     }

    //     input {
    //         color: rgba(26, 26, 26, 1);

    //         &::placeholder {
    //             color: rgba(26, 26, 26, 1);

    //         }
    //     }
    // }

    .new-phone-number-field .PhoneInputInput {
        display: none;
    }

    input {
        border: none !important;
    }
}

.offer-detail {
    .progress {
        background: rgba(204, 215, 255, 1) !important;
    }
}

.scanner-screen {
    div {
        height: 100%;
        padding-top: 0px !important;

        svg {
            // border: 100px solid rgba(0, 0, 0, 0) ;


            path {
                stroke: white !important;
            }
        }
    }

    video {
        object-fit: cover;
    }

    .scanner-header {
        position: absolute;
        z-index: 99;
        height: fit-content !important;
        top: 12px;
        width: 100%;
    }

    .cancel-scanner {
        position: absolute;
        z-index: 99;
        height: fit-content !important;
        bottom: 12px;
        width: 100%;

        a {
            width: 90%;
        }
    }
}

/* styles.css */
.animated-element {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.animated-element.active {
    opacity: 1;
    transform: translateY(0);
}

@media (min-width:578px) {
    .scanner-screen {
        svg {
            border: 150px solid rgba(0, 0, 0, 0) !important;
        }
    }
}

@media (max-width:578px) {
    .scanner-screen {
        svg {
            border: 100px solid rgba(0, 0, 0, 0) !important;
        }
    }
}

@media (max-width:375px) {
    .scanner-screen {
        svg {
            border: 70px solid rgba(0, 0, 0, 0) !important;
        }
    }
}