:root{
    --bs-blue:#3461FD;
}
.setting {
    .setting-user {
        border-radius: 50%;
        box-shadow: 0px 4px 6px 0px #1f436d73;

    }


    .switch {
        position: relative;
        display: inline-block;
        width: 55px;
        height: 30px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #EAEAEA;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 5px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: var(--bs-blue);
    }

    input:focus+.slider {
        box-shadow: 0 0 1px var(--bs-blue);
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    // .phone-number-field .PhoneInputCountryIcon--border img {
    //     width: 30px !important;
    //     height: 30px !important;
    // }

    // .PhoneInputCountrySelectArrow {
    //     margin-left: 18px !important;
    // }
    .phone-number-field {
        padding: 9px 18px !important;

        input {

            padding: 0px !important;


        }
    }

    // .opt-modal {
    //     .otp-stting {
    //         >div {
    //             justify-content: space-evenly;
    //         }

    //         input {
    //             width: 15% !important;
    //             height: 70px;
    //             border-radius: 12px;
    //             border: 1px solid #EAEFF5;

    //             &:focus-visible {
    //                 outline: none !important;
    //             }

    //         }
    //     }
    // }
}