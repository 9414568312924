.loyalty-page {
    // .scroll-div {
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100vh;
    //     background-color: lightgray;
    //     transition: opacity 0.3s ease;
    // }

    // .scroll-div.hide {
    //     opacity: 0;
    //     pointer-events: none;
    // }
    .input-group {
        background-color: white;
        border-radius: 50px;

        span {
            background-color: transparent;
        }

        input {
            padding: 12px 0px !important;
            font-size: 12px;
            color: #2F3542;
            font-weight: 500;

            &::placeholder {
                color: #979AA0;
            }
        }
    }

    .nav-pills .nav-link.active {
        color: #2F3542 !important;
        background-color: white;
        border: 1px solid white !important;


    }

    .nav-pills .nav-link {
        font-weight: 500 !important;
        border-radius: 50px;
        color: white !important;
        background-color: #3867FA;
        border: 1px solid #3742FA !important;

    }

    .loyalty-number {
        display: flex;
        justify-content: center;
        >div {
            display: flex;
            flex-wrap: wrap;
            width: 75%;
            div {
                width: 34px;
                height: 34px;
                margin: 4px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #000000;
            }
        }
    }

    .loyalty-offer {
        .col-7 {
            border: 2px solid rgb(28 29 34 / 6%);
            padding: 12px;
        }

        h4 {
            color: #7E7E7E;
            letter-spacing: 14px;
        }

        button {
            color: #2F3542 !important;
            border: 1px solid #2F3542 !important;
            font-family: 'Lato', sans-serif !important;
            font-size: 12px !important;
            text-transform: capitalize !important;
        }
    }
}

.QR-Code {
    .col-8 {
        border: 2px solid rgb(28 29 34 / 6%);
        padding: 12px;
    }

    h4 {
        color: #7E7E7E;
        letter-spacing: 14px;
    }

    .qr-button {
        color: #2F3542 !important;
        border: 1px solid #2F3542 !important;
        font-family: 'Lato', sans-serif !important;
        font-size: 12px !important;
        text-transform: capitalize !important;
    }
}

// .phone-number-field .PhoneInputCountryIcon--border img {
//     width: 30px !important;
//     height: 30px !important;
// }

// .PhoneInputCountrySelectArrow {
//     margin-left: 18px !important;
// }

// .opt-modal {
//     .otp-stting {
//         >div {
//             justify-content: space-evenly;
//         }

//         input {
//             width: 15% !important;
//             height: 70px;
//             border-radius: 12px;
//             border: 1px solid #EAEFF5;

//             &:focus-visible {
//                 outline: none !important;
//             }

//         }
//     }
// }