.get-know{
  input{
    border: 1px solid #DFDFE4 !important;
    padding: 18px;
    border-radius: 8px;
    &:focus{
        border: 1px solid #3867FA !important;
        border-radius: 8px !important   ;
    }
    &::placeholder{
      font-weight: 400 !important;
        color: #757575 !important;
    }
  }
}