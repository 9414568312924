:root {
  --gray: #72757a;
  --font1: 25px;
  --family1: "Inter";
  --style1: normal;
  --weight1: 600;
  --font2: 12px;
  --font3: 16px;
  --heightline: 125.3%;
  --family2: "Montserrat";
  --weight2: 400;
  --font4: 14px;
  --lightgray: rgba(18, 18, 18, 0.7);
  --lightgray1: #0a0a0a;
  --background1: #d6dbe5;
  --text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --colorblue: #3867fa;
  --ferozi: #5ad1f5;
  --ferozi2: #3fa9e5;
  --weight5: 500;
  --fontsize22: 1.375em;
  --colorblack: #121212;
  --black2: #5b5b5b;
  --weight8: 800;
}

@font-face {
  font-family: 'Roobert-bold';
  /* Specify the font-family name */
  src: url('../roobert/RoobertBold.ttf');
}

@font-face {
  font-family: 'Roobert-light';
  /* Specify the font-family name */
  src: url('../roobert/RoobertLight.ttf');
}

@font-face {
  font-family: 'Roobert-medium';
  /* Specify the font-family name */
  src: url('../roobert/RoobertMedium.ttf');
}

@font-face {
  font-family: 'Roobert-semibold';
  /* Specify the font-family name */
  src: url('../roobert/RoobertSemiBold.ttf');
}

@font-face {
  font-family: 'Roobert-regular';
  /* Specify the font-family name */
  src: url('../roobert/RoobertRegular.ttf');
}

/* Define custom font faces */
@font-face {
  font-family: 'helvetica-bold';
  /* Specify the font-family name */
  src: url('../helvetica-neue/HelveticaNeueBold.otf');
}

@font-face {
  font-family: 'helvetica-light';
  /* Specify the font-family name */
  src: url('../helvetica-neue/HelveticaNeueLight.otf');
}

@font-face {
  font-family: 'helvetica-medium';
  /* Specify the font-family name */
  src: url('../helvetica-neue/HelveticaNeueMedium.otf');
}

@font-face {
  font-family: 'helvetica-thin';
  /* Specify the font-family name */
  src: url('../helvetica-neue/HelveticaNeueThin.otf');
}

/* Apply fonts to corresponding classes */
.helvetica-bold {
  font-family: 'helvetica-bold', sans-serif !important;
  /* Use the correct font-family name */
}

.helvetica-light {
  font-family: 'helvetica-light', sans-serif !important;
  /* Use the correct font-family name */
}

.helvetica-medium {
  font-family: 'helvetica-medium', sans-serif !important;
  /* Use the correct font-family name */
}

.helvetica-thin {
  font-family: 'helvetica-thin', sans-serif !important;
  /* Use the correct font-family name */
}

.Roobert-bold {
  font-family: 'Roobert-bold', sans-serif !important;
  /* Use the correct font-family name */
}

.Roobert-semibold {
  font-family: 'Roobert-semibold', sans-serif !important;
  /* Use the correct font-family name */
}

.Roobert-medium {
  font-family: 'Roobert-medium', sans-serif !important;
  /* Use the correct font-family name */
}

.Roobert-light {
  font-family: 'Roobert-light', sans-serif !important;
  /* Use the correct font-family name */
}

.Roobert-regular {
  font-family: 'Roobert-regular', sans-serif !important;
  /* Use the correct font-family name */
}




html {

  font-size: 100%;
  overflow-x: hidden;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  overflow-x: hidden;
  /* background-color: #f9f9f9; */
}

input[type="text"]:focus-visible {
  outline: none !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12-86 {
  font-size: 12.86px !important;
}

.font-12-75 {
  font-size: 12.75px !important;
}

.font-13-86 {
  font-size: 13.86px !important;
}

.font-14-69 {
  font-size: 14.69px !important;
}

.font-11-09 {
  font-size: 11.09px !important;
}

.font-w-6 {
  font-weight: 600 !important;
}

.font-w-5 {
  font-weight: 500 !important;
}

.gray {
  color: #5b5b5b99 !important;
}

.active {
  font-weight: 500;
  font-size: 20px;
  color: black;
}

/* header of some screens css */
.logo {
  margin: auto;
}

a {
  text-decoration: none;
}

.font-11 {
  font-size: 11px !important;
}

.font-15-36 {
  font-size: 15.36px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-30 {
  font-size: 30px !important;
}

.text-blue {
  color: #3461fd !important;
}

.poppins {
  font-family: "poppins", sans-serif !important;
}

.inter {
  font-family: "Inter", sans-serif !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.rubik {
  font-family: "Rubik", sans-serif !important;
}

.lato {
  font-family: 'Lato', sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* common component css*/

.btn-fill-blue {
  background-color: #3867fa !important;
  border: 1.5px solid #3867fa !important;
  text-transform: capitalize !important;
  color: white !important;
}

.btn-fill-black {
  background-color: #000 !important;
  border: 1.5px solid #000 !important;
  text-transform: capitalize !important;
  color: white !important;
  width: 60%;
}

.btn-fill-gray {
  text-transform: capitalize !important;
  background-color: #d3d3d3 !important;
  border: 1.5px solid #d3d3d3 !important;

  color: white !important;
}

.btn-border {
  text-transform: capitalize !important;
  background-color: transparent !important;
  border: 1.5px solid #3867fa !important;
  color: #3867fa !important;
}

.backgroundwhite2 {
  background-color: white;
  margin: auto;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 70px;
}

/* common componenets css */

/* launch screen css */
.absolute1 {
  /* position: absolute;
  right: 0;
  top: 0; */
}

.launchscreen {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}

.height3 {
  height: 50vh;
}

.height4 {
  height: 380px;
}

.transparent-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.launnch-screen-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.scale-up {
  width: 40px;
  height: 40px;
}

.launnch-screen-content h5 {
  font-style: normal;
  font-size: var(--fontsize22);
  font-family: var(--family2);
  line-height: var(--heightline);
  font-weight: var(--weight1);
  color: var(--colorblack);
}

.launnch-screen-content div p {
  line-height: var(--heightline);
  font-family: var(--family2);
  font-weight: var(--weight5);
  font-size: var(--font2);
  color: var(--colorblack);
}

.launnch-screen-content p {
  line-height: var(--heightline);
  font-family: var(--family2);
  font-weight: var(--weight5);
  font-size: var(--font2);
  color: var(--ferozi2);
}

.terms {
  color: var(--ferozi2);
  text-decoration: underline;
}

.num-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* 
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 12.8551px;
line-height: 160.4%;
/* or 21px */
/* 
text-align: center;

color: rgba(18, 18, 18, 0.7); */
.react-tel-input {
  background-color: white;
  border-radius: 20px 20px 0px 0px;
}

.phonenum {
  background-color: aqua;
  width: 100%;
  border: none;
}

.form-control {
  background-color: transparent !important;

  border: none !important;
}

.form-control:focus {
  border-radius: 0 !important;
  box-shadow: none;
}

/* .react-tel-input .form-control {
  background-color: transparent !important;
  height: 52px;
  width: 330px !important;
  border-bottom: 2px solid var(--ferozi) !important;
} */
/* .react-tel-input .flag-dropdown {
  background-color: transparent !important;

  border: none !important;
}
.react-tel-input .flag {
  width: 14px !important;
  height: 12px !important;
  transform: scale(1.5);
} */
/* .react-tel-input .selected-flag .flag { */
/* position: absolute; */
/* top: 50%; */
/* margin-top: -10px; */
/* margin-top: -8px !important;
  margin-left: 10px; */
/* border-radius: 50px; */
/* clip-path: circle(50%);
}
.react-tel-input .be {
  background-position: -33px -10.7px !important;
} */
/* .react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: auto !important;
  height: 100%;
  padding: 0 !important;
  border-radius: 0 !important;
  display: flex;
  justify-content: start;
  align-items: center;
}
.react-tel-input .form-control {
  padding-left: 32px !important;
  font-size: 16.53px !important;
} */
/* launch screen css end */

/* number screenn */

.height2 {
  height: 42vh;
}

.continue-btn {
  /* background-color: var(--background1); */
  padding: 10px 15px;
  border-radius: 50px;
  border: none;
  color: white;
  font-family: "Poppins";
  font-style: var(--style1);
  font-weight: var(--weight1);
  font-size: var(--font2);
}

.number-sample {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

.bg-lights {
  background-color: #f7f7f7;
  padding: 10px 0;
}

.grid-containers {
  display: grid;
  grid-template-columns: auto auto auto;

  padding: 5px 2px;

  column-gap: 5px;
}

.grid-item {
  background: white;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 10px;
  font-size: 30px;
  border: none;
  text-align: center;
}

/* otp screen */
button {
  border: none;
}

.otps input {
  background-color: transparent;
  display: grid;
  grid-template-columns: auto auto auto;
  border: none;
  border-bottom: 1px solid var(--gray);
  outline: none;
  color: blue;
  margin-right: 0 !important;
}

.blue-border {
  border-bottom: 2px solid blue !important;
}

/* sign up screen */

.inputtype-text {
  background: #ffffff;
  border: 1px solid #d8dadc;
  border-radius: 10px;
  padding: 20px !important;
  width: 90%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.signup-form input[type="text"]::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: var(--weight2);
  font-size: var(--font3);
  line-height: 125%;

  color: rgba(0, 0, 0, 0.5);
}

.signup-form input[type="email"]::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: var(--weight2);
  font-size: var(--font3);
  line-height: 125%;

  color: rgba(0, 0, 0, 0.5);
}

.signup-form input[type="email"] {
  background: #ffffff;
  border: 1px solid #d8dadc;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.signup-form select {
  padding: 20px;
  width: 90%;
  background: #ffffff;
  border: 1px solid #d8dadc;
  border-radius: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: var(--weight2);
  font-size: var(--font3);
  line-height: 125%;

  color: rgba(0, 0, 0, 0.5);
}

.signup-form button[type="submit"] {
  background: #304ffe !important;
  padding: 20px;
  width: 90%;
  border: 1px solid rgb(0, 255, 157);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  color: white;
  cursor: pointer;
  font-family: var(--font1);
  font-style: var(--style1);
  font-weight: 600;
  font-size: 16px;
}

.signup-form label {
  display: block !important;
  width: 90%;

  display: flex;
  justify-content: center;
  margin: auto;

  font-family: var(--family2);
  font-style: var(--style1);
  font-weight: var(--weight2);
  font-size: var(--font4);
  line-height: 24px;

  color: #414141;
}

/*  sign in screen css*/

.launnch-screen-content4 button[type="submit"] {
  background: #304ffe !important;
  padding: 20px;
  width: 100%;
  border: 1px solid rgb(0, 255, 157);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  color: white;
  cursor: pointer;
  font-family: var(--font1);
  font-style: var(--style1);
  font-weight: 600;
  font-size: 16px;
}

.launnch-screen-content4 button[type="button"] {
  background: white !important;
  padding: 20px;
  width: 100%;
  border: 1px solid #747474;
  border-radius: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  color: black;
  margin-top: 10px;
  font-family: var(--font1);
  font-style: var(--style1);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

/* scan screen */

/* map screen */

.imgz {
  display: flex;
  justify-content: center;
  margin-top: -40px;
}

.imgz img {
  display: flex;
  justify-content: center;
}

.counts-div {
  background-color: #304ffe;
  margin: auto;
  width: 200px;
}

/* datepicker */

.custom-date-picker-container {
  display: flex !important;
  flex-direction: row-reverse;
  background-color: red;
  background: #ffffff;
  border: 1px solid #d8dadc;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.custom-date-picker {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  padding-left: 10px !important;

  border: none;
  border-radius: 3px;
  display: flex;
}

.custom-date-picker:focus {
  border: none;
  outline: none;
}

.date-picker-icon {
  content: url(../images/calendar-empty.png);

  /* position: absolute;
    top: 21px;
    left: 28px; */
}

/* scan screen css */
.feedback-Section {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 60% !important;
}

/* .c{
  position: relative;
  display: flex;
  justify-content: center;
}
.b{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.a{
padding: 10px 0;
 display:flex;
 justify-content: center;
}
.e{    
  position: absolute;
  left: 20px;
  top: 23px;
  background-color: transparent;
  border: 1px solid white;
  padding: 5px;
  color: white;

} */
.back_button {
  /* position: absolute; */
  top: 40%;
  left: 20px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  display: flex;
  padding: 7px;
  border-radius: 5px;
}

.background-image img {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%; */
}

.whitebg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 30px rgba(170, 170, 170, 0.16),
    0px 10px 20px rgba(194, 194, 194, 0.16);
  /* box-shadow: 1.07px 2.13px 4.27px 2.13px rgb(0 0 0 / 25%); */

  border-radius: 16px;
  margin: 10px 5px;
  padding: 20px 0;
}

.whitebg h4 {
  font-family: var(--family1);
  font-style: normal;
  font-weight: var(--weight5);
  font-size: var(--font1);
  line-height: 128%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  font-size: 12.7467px;
  line-height: 128%;

  --black2: #5b5b5b;
  color: var(--black2);
}

.whitebg>p {
  font-family: var(--family2);
  font-style: normal;
  font-weight: var(--weight1);
  font-size: var(--font4);
  line-height: 128%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ferozi);

  opacity: 0.7;
}

.whitebg h1 {
  font-family: var(--family1);
  font-style: normal;
  font-weight: var(--weight8);
  font-size: 65px;
  line-height: 65px;

  display: flex;
  align-items: center;
  color: var(--colorblue);
  justify-content: center;
}

.borderdashed {
  width: 100%;
}

.borderdashed img {
  width: 100%;
  margin-bottom: 10px;
}

.graybg {
  background-color: #edeeef;
  border-radius: 20px;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
}

.graybg img {
  width: 100px;
  background-color: transparent;
}

.delivery-header h3 {
  font-weight: 700;
  color: rgb(64 63 63);
  font-family: var(--family1);
}

.delivery-header p {
  font-size: var(--font3);
  font-family: var(--family1);
  color: var(--lightgray);
}

.bars-location1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 220px;

  margin: auto;
}

.bars-location1 img {
  padding: 10px;
}

.bars-location img {
  padding: 10px;
}

.bars-location h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.8608px;
  margin: 0;

  /* identical to box height, or 18px */

  display: flex;
  align-items: center;

  color: #000000;
}

c .bars-location1 h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.8608px;
  margin: 0;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;

  color: #000000;
}

.order-pickup-btn-dspl {
  background: #d6dbe5;
  /* border: 0.918224px solid #d6dbe5; */
  border-radius: 50px;
  padding: 20px;
  /* display: block; */
  color: white;
  font-size: 12px;
  /* width: 90%; */
  cursor: pointer;
  /* font-family: var(--family1); */
  font-style: normal;
  /* font-weight: var(--weight1); */
  font-family: 'Roobert-regular', sans-serif !important;
  /* Use the correct font-family name */

  font-size: 20px;
  line-height: 24px;
  /* position: absolute; */
  z-index: 1;
  /* left: 15px; */
  /* z-index: 1; */
  /* top: 70px; */
}

.valid {
  background-color: blue;
}

.order-pickup-btn-order {
  background: #d6dbe5;
  border-radius: 50px;
  padding: 10px;
  display: block;
  color: white;
  width: 90%;
  cursor: pointer;
  font-family: var(--family2);
  font-style: normal;
  font-weight: var(--weight1);
  font-size: 14px;

  margin-top: 20px;
}

.order-pickup-btn-order1 {
  background: #3867fa;

  border-radius: 50px;
  padding: 10px;
  display: block;
  color: white;
  width: 90%;
  cursor: pointer;
  font-family: var(--family2);
  font-style: normal;
  font-weight: var(--weight1);
  font-size: 14px;
  font-weight: 500;

  margin-top: 20px;
}

.btn-google {
  border: 1px solid #DFDFE4;
  background-color: #fff;
}

/* experience screen */

.absolute1 {
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -1; */
}

.bgimg {
  max-width: 100%;
  height: auto;
}

.signin-text {
  color: #111;
  align-items: flex-end;
  display: flex;
  margin: 0;
  padding: 10px;
  /* height: 212px; */
  justify-content: center;
  font-family: var(--family2);
  font-style: normal;
  font-size: var(--font4);
  line-height: 18px;

  position: absolute;
  bottom: 4%;
  left: 5%;
  right: 5%;
}

.white {
  background-color: red;
  height: 100px;
}

.order-detial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-detial h3 {
  font-family: var(--family1);
  font-style: normal;
  font-weight: var(--weight1);
  font-size: 17px;
  line-height: 128%;
  color: #000000;
  letter-spacing: 2px;
}

.order-detial p {
  font-family: "Inter";
  font-style: normal;
  font-weight: var(--weight1);
  font-size: var(--font2);
  line-height: 128%;
  color: var(--black2);
}

.whitebg1 {
  box-shadow: 0px 0px 30px rgba(170, 170, 170, 0.16),
    0px 10px 20px rgba(194, 194, 194, 0.16);
  border-radius: 10.6728px;
  margin: 10px 5px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 15px;
}

.stred {
  padding: 10px;
  font-size: 35px;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.stred:hover {
  background: rgba(250, 175, 59, 0.15);
}

/* 
.feedback{
  background-color: lightcoral;
} */
.feedback p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 171%;

  color: #5b5b5b;
}

.stred:hover::before {
  content: attr(title);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;

  color: #ffc107;
  padding: 5px 10px;
  border-radius: 10px;
  white-space: nowrap;
}

/* textarea {
  background-color: transparent;
} */

.areatext {
  height: 100px;
  background: #f9fafb;
  color: #d1d5db;
  border: none;
  border-radius: 8px;
  /* box-shadow: -1.06728px 0px 4.26911px rgba(0, 0, 0, 0.1) inset, 1.06728px 2.13456px 4.26911px rgba(0, 0, 0, 0.1) inset; */
  padding: 10px;
  resize: none;
  font-family: var(--family1);
  font-size: 16px;
}

.areatext::placeholder {
  /* font-family: var(--family1); */
  /* font-style: italic; */
  /* font-weight: var(--weight2); */
  /* font-size: 11px; */
  color: #d1d5db;
  line-height: 171%;
}

.areatext:focus-visible {
  border: none;
  outline: none;
}

.exp-heading {
  font-family: var(--family1);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;

  color: #000000;
}

.order-para {
  font-family: var(--family1);
  font-style: normal;
  font-weight: var(--weight5);
  font-size: 13px;
  line-height: 171%;
  /* or 22px */

  color: #5b5b5b;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-header {
  padding: 20px 0 0 0 !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.modal-content {
  border-radius: 1.3rem !important;
}

.modal-body h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.modal-body p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 171%;
  /* or 22px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #5b5b5b;
}

.padding-div {
  display: flex;
  justify-content: space-between;
}

.bg-blue {
  background-color: #3fa9e5;
  width: 30px;
  height: 30px;
  border: 2px solid #e9f8fd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: white;
}

.ferozibg {
  background: rgba(44, 185, 239, 0.1);
  padding: 20px;
  border-radius: 10.6728px;
}

.horizental-line {
  border-bottom: 2px solid #3fa9e5;
  position: absolute;
  left: 39px;
  top: 15px;
  /* padding: 0 10px; */
  width: 44px;
}

.horizental-line1 {
  border-bottom: 2px solid #3fa9e5;
  position: absolute;
  left: 124px;
  top: 16px;
  width: 44px;
}

.horizental-line2 {
  border-bottom: 2px solid #3fa9e5;
  position: absolute;
  right: 38px;
  top: 16px;
  width: 44px;
}

.phonenum::placeholder {
  color: #000000 !important;
}

.rating-div button {
  background: #ffffff;
  border: 1px solid #e1e4ed;
  border-radius: 501px;
  width: 90%;
  font-size: 14px;
  padding: 10px;
  color: #232360;
  font-family: var(--family2);
}

/* ----phone--number---field----- */
.phone-number-field .PhoneInputCountryIcon--border {
  box-shadow: none !important;
  background: transparent !important;
  /* padding: 12px; */
}

/* .phone-number-field .PhoneInputCountryIcon--border img {
  object-fit: cover;
  width: 22px;
  height: 22px;
  border-radius: 30px;
} */

.phone-number-field .PhoneInputCountry {
  display: none;

}

.phone-number-field input:focus-visible {
  outline: none;
}

.phone-number-field input {
  padding: 7px;
  background: transparent !important;
  font-weight: 500;
  font-size: 16.528px !important;
  border: none;
  padding: 18px !important;

}

.phone-number-field input::placeholder {
  color: #b9bdc5 !important;
}

.phone-number-field {
  background: transparent;
  border: 1px solid #DFDFE4;
  /* border-radius: 30px 30px 0px 0px; */
}

.phone-number-field-active {
  border: 1px solid #3867FA !important;
  /* border-radius: 30px 30px 0px 0px; */
}

.line::after {
  content: "";
  width: -webkit-fill-available;
  height: 2px;
  background-color: #3fa9e5;
  display: flex;
  position: absolute;
  top: 50%;
  z-index: 1;
  border-radius: 20px;
}

.z-3 {
  z-index: 3;
}

.refIdInput::placeholder {
  color: #b9bdc5;
}

.refIdInput {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 16.528px !important;
  border-bottom: 2px solid blue !important;
  border-radius: 30px 30px 0 0;
  font-family: var(--family2);
}

.refIdInput:focus {
  outline: none;
}

.qrTEXT {
  box-shadow: -1.07px 0px 4.27px 2.13px rgb(0 0 0 / 10%) !important;
}

.qrTEXT h1 {
  color: #848484;
  font-weight: bold;
  font-family: var(--family1);
}

.qrTEXT h3 {
  color: #848484;
  font-weight: bold;
  font-family: var(--family1);
}

.rounded4 {
  border-radius: 20px;
}

.qrTEXT p {
  color: black;

  font-family: var(--family2);
}

.spanTgas span {
  width: 52px;
  display: inline-flex;
  height: 52px;
  align-items: center;
  justify-content: center;
  margin: 5px;
  background-color: white;
  color: black;
  border-radius: 10px;
  text-align: center;
  font-family: var(--family1);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

/* .lin2active {
  background-color: #a46400;
}

.line3active {
  background-color: #853131;
} */

.okbutton {
  padding: 10px 30px;
  background-color: transparent;
  border-radius: 6.154px;
  border: 1.5px solid #A6A6A6;
  color: #A6A6A6;
  text-align: center;
  font-family: var(--family1);
  font-size: 15.386px;
  font-style: normal;
  font-weight: 500;
}

.my-button {
  font-family: var(--family1);
  padding: 10px;
  font-size: 15px;
  width: 100%;
  border-radius: 20px;
  margin: auto;
  text-align: center;
}

.blues {
  background-color: blue;
  color: white;
}

.qr-code-circle span {
  position: absolute;
  top: 0;

  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.qr-code-circle span.ok {
  background-color: #3867fa;
  /* Style for 'ok' span */
  left: -25px;
}

.qr-code-circle span.notok {
  background-color: #3867fa;
  /* Style for 'not ok' span */
  right: -25px;
}

.myborder {
  border: 2px solid black;
}

.rounded1 {
  border-radius: 18px;
}

.text-darked {
  font-size: 17px;
  color: black;
  font-weight: 600;
}

/* Add this CSS to style the loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #000000;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.myloader svg {
  animation: spin 2s linear infinite;
}

.leftText {
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  height: fit-content;
  width: fit-content;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 12px */
  letter-spacing: 0.48px;
  text-transform: uppercase;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  border-radius: 50px;
}

.loyaltyText {
  height: fit-content;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  /* 12px */
  letter-spacing: 0.48px;
  border-radius: 50px;
  background: rgb(76 118 250 / 50%);
  color: #000000 !important;
  padding: 5px 14px;
  border-radius: 50px;
  width: fit-content;

}

.settingText {
  height: fit-content;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  /* 12px */
  letter-spacing: 0.48px;
  border-radius: 50px;
  background: transparent;
  color: #4C76FA !important;
  padding: 8px 10px;
  border: 1px solid #E0E0E0;
  border-radius: 50px;
  width: fit-content;

}

.text-gray {
  color: #adadad !important;
}

.blue_bg {
  background-color: #3867fa !important;
}

.black_bg {
  background-color: #000000 !important;
}

.MuiFormControlLabel-label {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gender-textarea {
  border: 1px solid #D9D9D9 !important;
  resize: none;
  border-radius: 12px !important;
}

.gender-textarea:focus {
  border-radius: 12px !important;

}

.hyModal-textare textarea {
  border: 1px solid #D9D9D9 !important;
  resize: none;
  border-radius: 12px !important;
  color: #898989;

}

.hyModal-textare textarea:focus {
  border-radius: 12px !important;

}

.hyModal-textare textarea::placeholder {
  color: #898989;

}

.hyModal-textare span {
  background-color: #E2E2E1;
  padding: 6px 7px;
  border-radius: 10px;
  display: inline-flex;
  font-size: 25px;
  cursor: pointer;
}

.hyModal-textare span:hover {
  background-color: #3461FD;

}

.MuiModal-backdrop {
  background-color: #636366c7 !important;
}

@media (max-width: 376px) {
  .feedback-Section {
    height: 60% !important;
  }
}

@media (max-width: 331px) {
  .feedback-Section {
    height: 75% !important;
  }
}