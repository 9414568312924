@tailwind base;
@tailwind components;
@tailwind utilities;

.fixed-bottom-keyboard {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 1rem;
    flex-grow: unset !important;
}
